.above-class {
    width: 100%;
    min-height: auto; /* Allow height to adjust dynamically */
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-family: Arial, sans-serif;
    gap: 10px 30px;
    margin-top: 5%; /* Adjusted for consistent spacing */
    margin-bottom: 5%; /* Prevent excessive whitespace */
    padding: 0; /* Ensure no extra padding */
}

.info-card, .form-card {
    background: #fff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for better design */
    border-radius: 10px;
    flex: 1;
    max-width: 700px; /* Limit width for large screens */
    width: 100%; /* Allow full width for smaller screens */
    min-height: 300px; /* Ensure a minimum height for content */
    height: auto; /* Let height adjust dynamically */
    margin: 0;
}

.info-card p {
    display: flex;
    align-items: center;
    margin: 15px 0;
    font-size: 14px;
    line-height: 2.0;
    color: #000080;
}

.info-card p a {
    color: #000080;
    text-decoration: none;
    margin-left: 0;
}

.info-card p a:hover {
    text-decoration: underline;
}

.info-card svg {
    margin-right: 10px;
    color: #000080;
    font-size: 18px;
}

form {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Adjusted gap for responsiveness */
}

form label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
    color: #000080;
}

input, textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    resize: none;
}

.my-custom-button {
    padding: 10px 20px;
    background-color: #000080;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    align-self: center;
    width: auto;
}

.my-custom-button:hover {
    background-color: #000080;
}

/* Media Queries for Responsiveness */
@media (max-width: 1280px) {
    .above-class {
        gap: 15px;
    }

    .info-card, .form-card {
        max-width: 100%;
        flex: 1;
        height: auto; /* Dynamic height for content */
    }
}

@media (max-width: 1024px) {
    .above-class {
        gap: 20px;
    }

    .info-card, .form-card {
        max-width: 100%;
        height: auto; /* Dynamic adjustment */
    }
}

@media (max-width: 912px) {
    .above-class {
        gap: 25px;
    }

    .info-card, .form-card {
        max-width: 100%;
        height: auto;
    }
}

@media (max-width: 853px) {
    .above-class {
        flex-direction: column;
        gap: 20px;
        margin-top: 5%; /* Ensure consistent spacing */
        margin-bottom: 5%;
    }

    .info-card, .form-card {
        width: 100%; /* Use full width */
        height: auto; /* Remove hardcoded height */
    }
}

@media (max-width: 820px) {
    .above-class {
        flex-direction: column;
        gap: 20px;
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .info-card, .form-card {
        max-width: 100%;
        width: 100%;
        height: auto;
    }
}

@media (max-width: 768px) {
    .above-class {
        flex-direction: column;
        gap: 15px;
    }

    .info-card, .form-card {
        max-width: 100%;
        width: 100%;
        height: auto;
    }
}

@media (max-width: 480px) {
    .above-class {
        gap: 15px;
    }

    .info-card, .form-card {
        padding: 15px;
        max-width: 100%;
        height: auto;
    }

    form label {
        font-size: 12px;
    }

    input, textarea {
        padding: 8px;
    }

    .my-custom-button {
        width: 120px;
        padding: 8px;
        font-size: 14px;
    }
}

.error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}