/* .bot {
    background-image: url('../Navbar/Picture/work.png');    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  
  .pen {
    max-width: 90%;
    max-width: 850px;
    height: auto;
    max-height: 295px;
    border: 1px solid black;
    border-top-right-radius: 250px;
    border-bottom-right-radius: 250px;
    background-color: #0000B3;
    z-index: 10;
  
    text-align: left;
    padding: 20px;
    margin-bottom: 10%;
  }
  
  .got {

    text-decoration: none;
    gap: 20px;
    justify-content: center;
    padding: 2%;
  
  
  }
  
  
  .ten {
  
    text-decoration: none;
    color: white;
    font-size: 16px;
    gap: 1em;
    padding: 0.5em;
    transition: background-color 0.3s, color 0.3s;
  
  }
  
  
  .ten:hover {
  
    gap: 1em;
    background-color: #014CA6;
  
    border-radius: 5px;
    color: white;
  
  }
  
  
  @media (min-width: 768px) {
  
    .pen {
      width: 50%;
      padding: 5%;
      flex-direction: column;
      align-items: center;
      color: white;
  
    }
  
    .got {
      padding-right: 9.5%;
      padding-top: 2%;
    }
  
  }
  
  @media (max-width: 768px) {
    .bot {
      background-image: url('../Navbar/Picture/work.png');
      object-fit: cover;
  
      background-size: cover;
      background-position: center;
  
    }
  
    .pen {
      height: 150px;
  
      width: atuo;
  
      border-top-right-radius: 200px;
      border-bottom-right-radius: 200px;
    }
  
    .ele h1 {
      color: white;
  
      display: flex;
      flex-direction: column;
      align-items: left;
  
    }
  
    .ele p {
      color: white;
      flex-direction: row;
      justify-content: center;
    }
  }
 
  @media screen and (max-width: 768px) {
    .got {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
    
    }
    .pen {
        height: 150px;
    
        width: atuo;
    
        border-top-right-radius: 200px;
        border-bottom-right-radius: 200px;
      }
  
  } */


  .bot {
    background-image: url('../Navbar/Picture/work.png');    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  
  .pen {
    /* max-width: 90%; */
    max-width: 700px;
    height: 250px;
    max-height: 295px;
    border: 1px solid black;
    border-top-right-radius: 250px;
    border-bottom-right-radius: 250px;
    background-color: #0000B3;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 60px;
    margin-bottom: 10%;

  }
  
  .got {
    /* display: flex; */
    text-decoration: none;
    gap: 20px;
    justify-content: center;
    padding: 2%;
  
  
  }
  
  
  .ten {
  
    text-decoration: none;
    color: white;
    font-size: 16px;
    gap: 1em;
    padding: 0.5em;
    transition: background-color 0.3s, color 0.3s;
  
  }
  
  
  .ten:hover {
  
    gap: 1em;
    background-color: #014CA6;
  
    border-radius: 5px;
    color: white;
  
  }
  @media (min-width: 375px) {
  
    .pen {
      width: 50%;
      padding: 2%;
      flex-direction: column;
      align-items: center;
      color: white;
  
    }
    
    .ele h1{
    
      text-align: left; /* Center align text */
       /* Add space above the content */
      padding: 20px; /* Add padding inside the container */
  
    }
    .elp p {
      font-size: 18px; 
      text-align: left;
     




      /* padding: 20px;    */
    }
  }
  @media (max-width: 344px) {
    .pen {
      width: 50%; /* Further adjust the width for very small screens */
      padding: 0%; /* Add more padding for spacing */
      flex-direction: column;
      align-items: center;
      color: white;

}
.ele h1{
  font-size: 1.2rem;
  text-align: center; /* Center align text */
  margin-top: 50px; /* Add space above the content */
  padding: 20px; /* Add padding inside the container */


  
}

  }
  @media (min-width: 360px) {
  
    .pen {
   width:50%;
      padding: 0%;
      flex-direction: column;
      align-items: center;
      color: white;
  
    }
    .ele h1{
      font-size: 18px;
      
    }
  }
 
  
  
  @media (min-width: 768px) {
  
    .pen {
      width: 50%;
      padding: 2%;
      flex-direction: column;
      align-items: center;
      color: white;
  
    }
  
    .got {
      padding-right: 9.5%;
      padding-top: 2%;
    }
  
  }
  
  @media (max-width: 768px) {
    .bot {
      background-image: url('../Navbar/Picture/work.png');
      object-fit: cover;
  
      background-size: cover;
      background-position: center;
  
    }
  
    .pen {
      height: 250px;
  
      width: auto;
  
      border-top-right-radius: 200px;
      border-bottom-right-radius: 200px;
    }
  
    .ele h1 {
      color: white;
  
      display: flex;
      flex-direction: column;
      align-items: left;
      padding: 0.5%;
  
    }
  
    .ele p {
      color: white;
      flex-direction: row;
      justify-content: center;
      padding: 0.5%;
    }
  }
 

 
  @media screen and (max-width: 768px) {
    .got {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
    
    }
    .pen {
        height: 150px;
    
        width: auto;
    
        border-top-right-radius: 200px;
        border-bottom-right-radius: 200px;
      }
   
  
  }
  
  .pen h1 {
    font-size: 2.2rem; /* 190 / 64 = 2.9375, rounded to 2.9rem */
    /* Add any other styling properties you need */
  }
  
  .pen p {
    margin-left: 2%; /* Add margin-left to p element */
  }
  
  @media (max-width: 768px) {
    .pen h1 {
      font-size: 1.9rem; /* Adjust the font size for smaller screens */
      /* Add any other responsive styling properties you need */
    }
    .pen p {
      margin-left: 1.5%; /* Adjust margin-left for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .pen h1 {
      font-size: 1.5rem; /* Adjust the font size for even smaller screens */
      /* Add any other responsive styling properties you need */
    }
    .pen p {
      margin-left: 1%; /* Adjust margin-left for even smaller screens */
    }
  }