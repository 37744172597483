.backs {
  background-image: url('../Navbar/Picture/serveimg.png');
  object-fit: cover;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: space-between;


}

.logoo {
  max-width: 90%;
  max-width: 850px;
  height: auto;
  max-height: 295px;
  border: 1px solid black;
  border-top-right-radius: 250px;
  border-bottom-right-radius: 250px;
  background-color: #0000B3;
  z-index: 10;

  text-align: left;
  padding: 20px;
  margin-bottom: 10%;
}

.navbars {
  /* display: flex; */
  text-decoration: none;
  gap: 20px;
  justify-content: center;
  padding: 2%;


}


.tts {

  text-decoration: none;
  color: white;
  font-size: 16px;
  gap: 1em;
  padding: 0.5em;
  transition: background-color 0.3s, color 0.3s;

}


.tts:hover {

  gap: 1em;
  background-color: #014CA6;

  border-radius: 5px;
  color: white;

}


@media (min-width: 768px) {

  .logoo {
    width: 50%;
    padding: 5%;
    flex-direction: column;
    align-items: center;
    color: white;

  }

  .navbars {
    padding-right: 9.5%;
    padding-top: 2%;
  }

}

@media (max-width: 768px) {
  .backs {
    background-image: url('../Navbar/Picture/serveimg.png');
    object-fit: cover;

    background-size: cover;
    background-position: center;

  }

  .logoo {
    height: 150px;
    /* align-items: left; */
    width: atuo;

    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;
  }

  .vote h1 {
    color: white;

    display: flex;
    flex-direction: column;
    align-items: left;

  }

  .vote p {
    color: white;
    flex-direction: row;
    justify-content: center;
  }
}



/* 

@media (max-width: 768px) {
  .workoff {
   
    
    max-width: 100%;
    width: 900px;
    position: absolute;
    margin-top: -2%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .workoff1 {

    height: 120px;
    margin-top: -2%;
    width: 85%;
    max-width: 85%;
    height: auto;
    border-radius: 20px;
    border-top-left-radius: 250px;
    border-bottom-left-radius: 250px;
  }
}
@media (min-width:768px){
  .workoff {
    display: flex;
    flex-direction: row;
    justify-content: end;
    max-width: 100%;
    width: 900px;
    position: absolute;
    margin-top: -8%;
    
  
   
  }

  .workoff1 {

    height: 120px;
    margin-top: -2%;
    width: 85%;
    max-width: 100%;
    height: auto;
    border-radius: 20px;
    border-top-left-radius: 250px;
    border-bottom-left-radius: 250px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: flex-end;
   
  }
}
 */

 /* For screens smaller than 768px */
@media (max-width: 768px) {
  .workoff {
    max-width: 100%;
    width: 100%; /* Ensure it stretches fully for smaller screens */
    position: absolute;
    margin-top: -2%;
    display: flex;
    justify-content: flex-end; /* Align content to the right */
    flex-direction: row;
  }

  .workoff1 {
    height: auto;
    margin-top: -2%;
    width: 85%; /* Adjust for smaller screens */
    max-width: 85%;
    border-radius: 20px;
    border-top-left-radius: 250px;
    border-bottom-left-radius: 250px;
  }
}

/* For screens 768px and larger */
@media (min-width: 768px) {
  .workoff {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; 
    max-width: 100%;
    width: 900px;
    position: absolute;
    right: 0;
    margin-top: -8%;
  }

  .workoff1 {
    display: flex;
    justify-content: flex-end; 
    align-items: center; 
 
    height: auto;
    width: auto;
    width: 100%; 
    max-width: 850px;
    margin-top: -2%;
    border-radius: 20px;
    border-top-left-radius: 250px;
    border-bottom-left-radius: 250px;
    margin-right: 0; 
  }
}


.text-overlay-container h1 b {
  font-size: calc(1.8rem + 0.5vw); 
  color: inherit; 
  line-height: 1.3;
  font-weight: bold; 
  word-wrap: break-word; 

  text-align: justify;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: -40%;
}


@media (max-width: 780px) {
  .text-overlay-container h1 b {
      font-size: 1.8rem; 
      line-height: 1.2;
      text-align: justify;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: -40%;
      max-width: 100%;
  
  }
}


@media (max-width: 480px) {
  .text-overlay-container h1 b {
      font-size: 1.5rem; 
      line-height: 1.1;
      text-align: justify;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: -40%;
      max-width: 100%;
  }
}

.text-overlay-container {
  position: relative;
  
  

 

}

.hh, .ff, .gf, .tf {
 
  width: 100%;
  height: 439px;
  padding-top: 50px;
  padding-left: 70px;
  position: relative;
}

.hh {
  background: url('../Navbar//Picture/laptop1.PNG');
  background-position: 100%;
 

  background-size: cover;
}

.ff {
  background: url('../Navbar/Picture/text1.PNG');
  background-position: 100%;

 
  background-size: cover;
}

.gf {
  background: url('../Navbar/Picture/social1.PNG');
  background-position: 100%;

 
  background-size: cover;
}

.tf {
  background: url('../Navbar/Picture/color1.PNG');
  background-position: 100%;


  background-size: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

.hh h1, .ff h1, .gf h1, .tf h1,
.hh p, .ff p, .gf p, .tf p {
  position: relative;
  z-index: 2;
  
  
}

.er {
  font-size: 32px;
  text-align: justify;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: -40%;
  color: black;
}
@media (max-width: 912px) {
  .er {
    font-size: 20px;
}
}
@media (max-width: 1024px) {
  .er {
    font-size: 20px;
}
}



@media (max-width: 768px) {

  .back {
      height: auto;
      background-position: center;
  }

  .lfg {
      width: 100%;
      height: auto;
      margin: 0 auto;
      border-radius: 0;
  }

  .bbnh {
      font-size: 28px;
      margin-left: 5%;
      text-align: center;
  }

  .hh, .ff, .gf, .tf {
      padding-left: 5%;
      height: auto;
  }

  .hh h1, .ff h1, .gf h1, .tf h1 {
      font-size: 28px;
  }

  .er {
      font-size: 16px;
      padding-left: 5%;
      padding-right: 5%;
     
  }
  .Gapp{
    margin-top: 40%;
  }

  
}

@media (max-width: 480px) {
  .back {
      height: 300px;
      background-size: contain;
      background-position: center;
      
  }

  .hh h1, .ff h1, .gf h1, .tf h1 {
      font-size: 20px;
  }

  .er {
      font-size: 14px;
  }
  .Gapp{
    margin-top: 40%;
  }

  
}
@media (min-width: 769px) and (max-width: 880px) {
  .back {
      height: 450px; 
      background-position: center;
  }

  .lfg {
      width: 100%;
      height: auto;
      margin: 0 auto;
      border-radius: 0;
  }

  .bbnh {
      font-size: 35px;
      margin-left: 10%;
      text-align: center;
  }

  .hh, .ff, .gf, .tf {
   
      width: 100%; /* Ensure the sections don't exceed the screen width */
      height: auto;
      padding-left: 5%;
      padding-right: 5%; /* Adding right padding to balance the layout */
      position: relative;
  }

  .hh h1, .ff h1, .gf h1, .tf h1 {
      font-size: 28px;
  }

  .er {
      font-size: 18px; /* Reduce font size for better alignment */
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 10px;
      color: black;
     
  }
  .Gapp{
    margin-top: 40%;
  }

  .text-overlay-container h1 b {
      font-size: 2rem; /* Adjusting font size */
      padding-left: 5%;
      padding-right: 5%;
      text-align: left; /* Centering the title */
    
  }

}




@media screen and (max-width: 768px) {
  .navbars {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  
  }
  .Gapp{
    margin-top: 40%;
  }

}

@media (min-width: 820px) and (max-width: 853px) {
  body, html {
    margin: 0;
    padding: 0;
    width: 100%; /* Ensure the root elements span the full width */
    overflow-x: hidden; /* Prevent horizontal scroll */
  }

  .backs {
    width: 100%; /* Make the container stretch fully */
    height: auto;
    background-size: cover;
    background-position: center;
    margin: 0;
  }

  .hh, .ff, .gf, .tf {
    width: 100%; /* Ensure content areas span the full screen */
    padding-left: 0;
    padding-right: 0;
    margin: 0;
  }

  .logoo {
    max-width: 100%; /* Stretch logo container */
    padding: 0; /* Remove padding */
    margin: 0 auto; /* Center-align */
    display: flex;
    justify-content: center; /* Center content inside */
    align-items: center;
  }

  .navbars {
    width: 100%; /* Span the navigation bar */
    display: flex;
    justify-content: center; /* Center navigation links */
    flex-wrap: wrap; /* Allow wrapping if necessary */
    gap: 10px; /* Adjust spacing between items */
    padding: 0; /* Remove extra padding */
  }

  .workoff, .workoff1 {
    width: 100%; /* Ensure elements fit the screen width */
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end; /* Adjust alignment as needed */
  }

  .text-overlay-container h1 b {
    font-size: 1.8rem; /* Adjust font size */
    line-height: 1.2;
    padding: 5%; /* Consistent padding */
    text-align: center;
  }

  .er {
    font-size: 16px; /* Adjust text size */
    padding-left: 5%;
    padding-right: 5%;
    line-height: 1.5;
    color: black;
  }
  .Gapp{
    margin-top: 30%;
  }
 

}



/* Base Styles */
.services-container {
width: 100%;
text-align: justify;
align-items: flex-start;
/* padding-top: 10%; */
/* padding-bottom: 30%; */
  /* margin: auto; */
  /* padding: 20px; */
}

.service-box {
  display: flex;
  align-items: center;
  background-color: #074799; /* Dark Blue */
  color: white;
  /* border-radius: 10px; */
  /* overflow: hidden; */
  /* margin-bottom: 20px; */
  margin: 7% 0%;
  padding: 20px;
}

.service-image {
  width: 40%;
  height: 55vh;
 
  border-radius: 8px;
}

.service-content {
  width: 60%;
  padding: 7%;
  justify-content: left;
  
}

.service-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.service-content p {
  font-size: 16px;
}

/* Alternate Row Styling */
.row-normal {
  flex-direction: row;
}

.row-reverse {
  flex-direction: row-reverse;
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
  /* Tablet View */
  .service-box {
    flex-direction: column;
    text-align: justify;
  }
  
  .service-image {
    width: 100%;
    height: 53vh;
    resize: contain;
  }

  .service-content {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  /* Mobile View */
  .service-box {
    flex-direction: column;
    text-align: justify;
    align-items: flex-start;
  }
  .service-image {
    width: 100%;
    height: auto;
  }

  .service-content {
    width: 100%;
  }

  .service-content h2 {
    font-size: 20px;
  }

  .service-content p {
    font-size: 14px;
  }
  
}

@media screen and (max-width: 468px) {
  /* Mobile View */
  .service-box {
    flex-direction: column;
    text-align: justify;
    align-items: flex-start;
  }
  .service-image {
    width: 100%;
    height: auto;
  }

  .service-content {
    width: 100%;
  }

  .service-content h2 {
    font-size: 20px;
  }

  .service-content p {
    font-size: 14px;
  }
  
}
