
.footer {
    background-color: rgb(29, 29, 113);
    color: #fff;
    padding: 3%;
    text-align: left;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
   /* max-height: 70% */
  
    /* font-family: Arial, sans-serif; */
  }
  
  .footer-content {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .footer-section {
    flex: 1 1 calc(25% - 20px);
    box-sizing: border-box;
    min-width: 200px;
    display: flex;
  flex-direction: column;
  
  }
  
  .footer-section h4 {
    margin-bottom: 15px;
    font-size: 18px;
  }
  
  .footer-section a,
  .footer-section p {
    color: #b1b1b1;
    text-decoration: none;
    font-size: 14px;
  }
  
  .footer-section a:hover {
    color: #fff;
  }
  
  .location-info {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .location-icon {
    margin-right: 10px;
    color: white;
    font-size: 16px;
  }
  
  .social-icons {
    margin-top: 20px;
    text-align: left;
  }
  
  .social-icons h4 {
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .social-icons a {
    margin: 0 10px;
    color: #fff;
    font-size: 20px;
    text-decoration: none;
  }
  
  .social-icons a:hover {
    /* color: #b1b1b1; */
    color: black;
  }
  
  .footer-image {
    text-align: center;
    margin-top: 30%;
  }
  
  .footer-image img {
    height: 50px;
    max-width: 100%;
    object-fit: contain;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: row;
      flex-wrap: wrap;
      /* align-items: flex-start; */
    }
  
    .footer-section {
      flex: 1 1 100%;
      margin-bottom: 20px;
    }
  
    
    .footer-image img {
      margin: 0 auto;
    }
    .social-icons {
      /* display: flex;
      flex-direction: column; */
      margin-top: 5%;
      text-align: left;
      width: 100%;
    }
    .footer-image {
      text-align: center;
      margin-top: 20px;
    }
  
  }
  
  @media (max-width: 480px) {
    /* .footer-content{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    } */
    .footer-section h4 {
      font-size: 16px;
    }
  
    .footer-section a,
    .footer-section p {
      font-size: 12px;
    }
  
    .social-icons a {
      font-size: 18px;
    }
    .footer-image {
      text-align: center;
      margin-top: 20px;
    }
  }
  
  
  