/* .goods {
    background-image: url('../Navbar/Picture/mk.png');
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: space-between;
  
  
  }
  
  .logoot {
    max-width: 90%;
    max-width: 850px;
    height: auto;
    max-height: 295px;
    border: 1px solid black;
    border-top-right-radius: 250px;
    border-bottom-right-radius: 250px;
    background-color: #0000B3;
    z-index: 10;
  
    text-align: left;
    padding: 20px;
    margin-bottom: 10%;
  }
  
  .navbarss {
   
    text-decoration: none;
    gap: 20px;
    justify-content: center;
    padding: 2%;
  
  
  }
  
  
  .ttts {
  
    text-decoration: none;
    color: white;
    font-size: 16px;
    gap: 1em;
    padding: 0.5em;
    transition: background-color 0.3s, color 0.3s;
  
  }
  
  
  .ttts:hover {
  
    gap: 1em;
    background-color: #014CA6;
  
    border-radius: 5px;
    color: white;
  
  }
  
  
  @media (min-width: 768px) {
  
    .logoot {
      width: 50%;
      padding: 5%;
      flex-direction: column;
      align-items: center;
      color: white;
  
    }
  
    .navbarss {
      padding-right: 9.5%;
      padding-top: 2%;
    }
  
  }
  
  @media (max-width: 768px) {
    .goods {
      background-image: url('../Navbar/Picture/mk.png');
      object-fit: cover;
  
      background-size: cover;
      background-position: center;
  
    }
  
    .logoot {
      height: 150px;
  
      width: atuo;
  
      border-top-right-radius: 200px;
      border-bottom-right-radius: 200px;
    }
  
    .votess h1 {
      color: white;
  
      display: flex;
      flex-direction: column;
      align-items: left;
  
    }
  
    .votess p {
      color: white;
      flex-direction: row;
      justify-content: center;
    }
  }
  
@media screen and (max-width: 768px) {
    .navbarss {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
    
    }
    .logoot {
        height: 150px;
    
        width: atuo;
    
        border-top-right-radius: 200px;
        border-bottom-right-radius: 200px;
      }
  
  }

  .mission-section {
  
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .mission-content {
    flex: 1 1 60%;
    max-width: 60%;
    padding: 20px;
  }
  
  .mission-content h1 {
    color: #2a2a8f;
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .mission-content p {
    font-size: 1rem;
    color: #333;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .mission-content h4 {
    color: #2a2a8f;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1.2rem;
  }
  
  .mission-image {
    flex: 1 1 35%;
    max-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    padding: 20px;
  }
  
  .mission-image img {
    
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  @media screen and (max-width: 768px) {
    .mission-content {
      max-width: 100%;
      flex: 1 1 100%;
    }
  
    .mission-image {
      max-width: 100%;
      flex: 1 1 100%;
      margin-top: 20px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .mission-content h1 {
      font-size: 1.5rem;
    }
  
    .mission-content h4 {
      font-size: 1rem;
    }
  
    .mission-content p {
      font-size: 0.9rem;
    }
  }
.dots{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  background-color: #f9f9f9;
}
  
   .kj{
    background-color: #f9f9f9;
   }
   
   
   .toy{
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius: 5px;
   }
    @media (max-width:768px){
    
      .kj{
        display: flex;
        flex-direction: column;
      }
      .toy{
        max-width: 100%;
        flex: 1 1 100%;
     
      }
    } */


    .goods {
      background-image: url('../Navbar/Picture/mk.png');
      object-fit: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      max-width: 100%;
      height: auto;
      position: relative;
      display: flex;
      justify-content: space-between;
    
    
    }
    
    .logoot {
      max-width: 90%;
      max-width: 850px;
      height: auto;
      max-height: 295px;
      border: 1px solid black;
      border-top-right-radius: 250px;
      border-bottom-right-radius: 250px;
      background-color: #0000B3;
      z-index: 10;
    
      text-align: left;
      padding: 20px;
      margin-bottom: 10%;
    }
    
    .navbarss {
      /* display: flex; */
      text-decoration: none;
      gap: 20px;
      justify-content: center;
      padding: 2%;
    
    
    }
    
    
    .ttts {
    
      text-decoration: none;
      color: white;
      font-size: 16px;
      gap: 1em;
      padding: 0.5em;
      transition: background-color 0.3s, color 0.3s;
    
    }
    
    
    .ttts:hover {
    
      gap: 1em;
      background-color: #014CA6;
    
      border-radius: 5px;
      color: white;
    
    }
    @media (min-width: 375px) {
    
      .logoot {
        width: 22%;
        padding: 5%;
        flex-direction: column;
        align-items: center;
        color: white;
    
      }
    
    }
    @media (min-width: 344px) {
    
      .logoot {
        width: 22%;
        padding: 2%;
        flex-direction: column;
        align-items: center;
        color: white;
    
      }
    
    
    
    }
    @media (min-width: 390px) {
    
      .logoot {
        width: 22%;
        padding: 5%;
        flex-direction: column;
        align-items: center;
        color: white;
    
      }
    
    
    
    }
    @media (min-width: 360px) {
    
      .logoot {
        width: 20%;
        padding: 2%;
        flex-direction: column;
        align-items: center;
        color: white;
    
      }
    
    
    
    }
    
    
    @media (min-width: 768px) {
    
      .logoot {
        width: 45%;
        padding: 5%;
        flex-direction: column;
        align-items: center;
        color: white;
    
      }
    
      .navbarss {
        padding-right: 9.5%;
        padding-top: 2%;
      }
    
    }
    
    @media (max-width: 768px) {
      .goods {
        background-image: url('../Navbar/Picture/mk.png');
        object-fit: cover;
    
        background-size: cover;
        background-position: center;
    
      }
    
      .logoot {
        height: 150px;
    
        width: auto;
    
        border-top-right-radius: 200px;
        border-bottom-right-radius: 200px;
      }
    
      .votess h1 {
        color: white;
    
        display: flex;
        flex-direction: column;
        align-items: left;
    
      }
    
      .votess p {
        color: white;
        flex-direction: row;
        justify-content: center;
      }
    }
    
  @media screen and (max-width: 768px) {
      .navbarss {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
      
      }
      .logoot {
          height: 150px;
      
          width: auto;
      
          border-top-right-radius: 200px;
          border-bottom-right-radius: 200px;
        }
    
    }
  
    .mission-section {
    
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      padding: 20px;
      background-color: #f9f9f9;
    }
    
    .mission-content {
      flex: 1 1 60%;
      max-width: 60%;
      padding: 20px;
    }
    
    .mission-content h1 {
      color: #2a2a8f;
      font-size: 2rem;
      margin-bottom: 10px;
    }
    
    .mission-content p {
      font-size: 1rem;
      color: #333;
      margin-bottom: 20px;
      line-height: 1.6;
    }
    
    .mission-content h4 {
      color: #2a2a8f;
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 1.2rem;
    }
    
    .mission-image {
      flex: 1 1 35%;
      max-width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5%;
      padding: 20px;
    }
    
    .mission-image img {
      
      max-width: 100%;
      width: 100%;
      height: auto;
      border-radius: 5px;
    }
    
    @media screen and (max-width: 768px) {
      .mission-content {
        max-width: 100%;
        flex: 1 1 100%;
      }
    
      .mission-image {
        max-width: 100%;
        flex: 1 1 100%;
        margin-top: 20px;
      }
    }
    
    @media screen and (max-width: 480px) {
      .mission-content h1 {
        font-size: 1.5rem;
      }
    
      .mission-content h4 {
        font-size: 1rem;
      }
    
      .mission-content p {
        font-size: 0.9rem;
      }
    }
  .dots{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    background-color: #f9f9f9;
  }
     .kj{
      background-color: #f9f9f9;
     }
     
     
     .toy{
      max-width: 100%;
      width: 100%;
      height: auto;
      border-radius: 5px;
     }
      @media (max-width:768px){
      
        .kj{
          display: flex;
          flex-direction: column;
        }
        .toy{
          max-width: 100%;
          flex: 1 1 100%;
          /* margin-top: 20px; */
        }
      }
